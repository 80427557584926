/* BoostMeCard.css */
.card-container {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  margin: 20px auto;
  position: relative;
}

.card-content {
  text-align: center;
  padding: 20px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.logo {
  font-size: 50px;
  /* margin-bottom: 5px; */
}

h1 {
  font-size: 24px;

  margin-bottom: 85px;
  color: #333;
}

.qr-code {
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 2;
  top: -75px; /* Esto mueve la imagen del QR hacia arriba para que la mitad quede fuera del footer */
  margin-bottom: -75px; /* Ajusta el margen inferior para compensar el movimiento hacia arriba */
}

.footer {
  background-color: #ff8c42;
  padding: 75px 15px 15px; /* Ajusta el padding superior para cubrir más espacio */
  color: white;
  font-size: 14px;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-top: -75px; /* Superposición con el contenido superior */
}

.footer strong {
  display: block;
  margin-top: 5px;
  font-size: 16px;
}