/* App.css */
body {
  background-color: #f5f5f5;
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  margin-top: 35px;
  color: #c0c0c0;
}

/* .banner {
  width: 100%;
  height: auto;
  margin-top: 20px;
} */

.mempool-fees {
  font-size: 14px;
  font-style: italic;
  margin-top: 7px; /* Ajustamos el espaciado del texto de las tarifas */
  font-weight: 700;
}

.button {
  background-color: #ff8c42;
  color: white;
  padding: 5px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-top: -20px;
  margin-bottom: 5px;
  width: 100%;
  position: relative;
  text-align: center;
}

.btc-price {
  margin-top: 7px; /* Subimos el texto de la cotización de BTC */
  font-size: 16px;
  font-style: italic;
}

.input-group {
  background-color: #e0e0e0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.input-group input {
  border: none;
  background: transparent;
  text-align: right;
  font-size: 16px;
  width: 70%;
}

.input-group img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.input-group button {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  font-size: 16px;
}

input:focus {
  outline: none;
}

.text-credit {
  font-size: 1em;
  font-weight: 600;
  color: #ff8c42;
  font-style: italic;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.2;
}

.text-credit p{
  margin: 7px;
}
.text-credit a{
  text-decoration: none;
  color:#ff8c42;
  font-weight: 900;
} 
