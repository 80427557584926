.banner {
  width: 100%;
  height: 200px; /* Ajusta la altura si es necesario */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -17px;
}

.banner img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}